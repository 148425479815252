<template>
  <div class="app-item app-content">
    <div class="table-content">
    <h4>我的测评</h4>
    <el-table style="width: 100%" :data="tableData">
      <el-table-column prop="title" label="考卷名称" align="center"></el-table-column>
      <el-table-column prop="time" label="考试时长" align="center"></el-table-column>
      <el-table-column prop="desc" label="说明" align="center"></el-table-column>
      <el-table-column prop="" label="" align="center">
        <template slot-scope="props">
          <el-button size="mini" type="primary" v-on:click="startTest(props.row.id)">立即考试</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Page :pageSize="15" :total="total" @goTo="goTo"></Page>
  </div>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
export default {
  components: { Page },
  data () {
    return {
      total: 0,
      tableData: [],
      searchParam: {
        page: 1
      }
    }
  },
  methods: {
    startTest (id) {
      this.$router.push({ name: 'PaperInfo', params: { id: id } })
    },
    getPapers () {
      this.tableData = []
      this.$store.dispatch('getPapers', this.searchParam).then(res => {
        this.tableData = res.data
        this.total = res.meta.total
      })
    },
    goTo (page) {
      // console.log(page)
      this.searchParam.page = page
      this.getPapers()
    }
  },
  mounted () {
    this.getPapers()
  }
}
</script>
<style lang="less" scoped>
</style>
